
import Kirbylink from '../kirbylink.vue';

export default
{
    name: "servicemincard",
    props: {
        service: Object
    },
    components: { Kirbylink }
}

