
import Kirbylink from "./kirbylink.vue";
import Servicemincard from "./v2/servicemincard.vue";
import Swiper from "swiper";

export default {
  components: { Servicemincard, Kirbylink },
  props: {
    content: Object,
    page: Object,
  },
  data() {
    return {
      services: [],
    };
  },
  name: "solutionspeek",
  mounted() {
    this.$axios
      .$post(`/api/content`,
        {
          query: "site.index.template('service')",
          select: {
            listing: "page.blocks.toListingHelper",
          },
        }
      )
      .then((resp) => {
        this.services = resp.result.data;
      })
      .finally(() => {
        new Swiper(".swiper-peek", {
          hashNavigation: {
            watchState: true,
          },
          loop: false,
          slideToClickedSlide: true,
          slidesPerView: "auto",
          spaceBetween: 30,
          pagination: {
            el: "#swiper-pagination-peek",
            clickable: true,
            type: "bullets",
          },
        });
      });
  },
};
